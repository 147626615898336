import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
const WorkDescriptionPage = ({ location }) => {
  return (
    <Layout pagePath={location.pathname}>
      <div className="flex flex-col mt-14">
        <h1 className=" text-scoringBlue">Work Description</h1>
        <p>
          The project will be carried out over 3 years. Our plan, over this
          duration, is to approach the project incrementally in order to be able
          to monitor its advancement at any time. We have split the project into
          five work packages, each of which contains 3 to 4 tasks. Following a
          figure that showcase the separation of work packages of the project.
        </p>
        <StaticImage
          src="../images/scoring_work_packages.png"
          alt="SCORING Work Packages"
          className="w-full object-center mt-6"
        />
        <ul className="list-disc pl-12 mt-14">
          <li>
            <strong className="text-scoringBlue">
              WP1 (Project Management, Dissemination and Exploitation)
            </strong>{" "}
            will monitor all the organizational aspects of the collaboration. In
            order to assess how the project is evolving and to exchange
            information and know-how about their developments so far, the
            partners will have bi-weekly virtual meetings via video-conferencing
            and will meet regularly in person (twice a year). Internal
            communication (through dedicated collaborative tools over the
            Internet) as well as external communication (web and social media)
            will also be defined, kept alive and assessed for their efficiency
            within WP1. Due to the important geographic spread of the partners,
            a specific focus will be given to the internal communication as it
            is a key to ensure the team will work closely in the project;
            extensive use within the project of dedicated collaborative tools
            over the Internet will be continuously advocated and monitored to
            ensure efficiency.
          </li>
          <li>
            <strong className="text-scoringBlue">
              WP2 (Towards an Integrated Computing and Networking Architecture
              for SCORING)
            </strong>{" "}
            will target building the overall architecture of the targeted “Smart
            Collaborative cOmputing, caching and netwoRking paradIgm for Next
            Generation communication infrastructures (SCORING)”. This will be
            constructed gradually, starting from exhaustive definition of the
            SCORING use cases, followed by the derivation of user and system
            requirements as well as key performance indicators (KPI) related to
            SCORING. A baseline prototype implementation of the basic SCORING
            paradigm building block will be achieved in this WP. All this will
            be made while keeping in mind the different strata and associated
            mechanisms to be developed in WP3 and WP4.
          </li>
          <li>
            <strong className="text-scoringBlue">
              WP 3 (Smart Placement of Computing Service in SCORING)
            </strong>{" "}
            perimeter will target: (i) the proactive placement of computing
            services, while taking into account users mobility as well as
            per-computing-node battery status and computing load (@ the
            Computing stratum); (ii) the proactive placement of content stores
            while keeping in mind joint networking and computing constraints (@
            the storage stratum); and (iii) enforcement of dynamic routing
            policies, using SDN, to satisfy the distributed end- user compute
            requirements and QoE (@ the networking stratum).
          </li>
          <li>
            <strong className="text-scoringBlue">
              WP 4 (Optimal Information-Centric Networking and Computing -
              including Storage - in SCORING)
            </strong>{" "}
            perimeter will span the: (i) efficient management of micro-services
            in such a multi-tenant distributed realm, by exploiting the
            Information-Centric Networking principles to support both name and
            compute function resolution (@ the software stratum); (ii) optimal
            caching of specific contents, while keeping in mind joint networking
            and computing constraints (@ the storage stratum); and (iii)
            designing new network-economic models to support service offering in
            an optimal way, while considering the multi-stakeholder feature of
            the collaborative computing and networking paradigm proposed in this
            project (@ the resource management stratum).
          </li>
          <li>
            <strong className="text-scoringBlue">
              WP 5 (Integration, Validation and Demonstration)
            </strong>{" "}
            will aim at demonstrating the feasibility of the proposed strata on
            both a realistic and integrated test-bed as well as on a
            consolidated simulation platform (based on available open-source
            network-simulation toolkits). The test-bed will be built by
            exploiting different virtualization (VM/Containers) technologies to
            deploy compute and storage functions within a genuine networking
            architecture.
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default WorkDescriptionPage
